import React from "react";
import EmiFd from "./EmiFd";
import HeroSlider from "./HeroSlider";
import RateCharges from "./RateCharges";
import Services from "./Services";
import MiscCards from "./MiscCards";
import Contact from "../../Components/Contact";
import Addvertisement from "../../images/newpopup.png";
import cross from "../../images/cross.png";
import { useEffect, useState } from "react";
const Home = () => {
  const [showAd, setShowAd] = useState(false);
  useEffect(() => {
    document.title = "Gurkhas Finance";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    showAd ? body.classList.add("show") : body.classList.remove("show");
  }, [showAd]);

  return (
    <>
      <HeroSlider />
      <Services />
      <EmiFd />
      <RateCharges />
      <MiscCards />
      <div
        className={showAd ? "home-ad-overlay" : "home-ad-overlay ad-hide"}
        onClick={() => setShowAd(false)}
      >
        <div
          className="image-container"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="cross-container" onClick={() => setShowAd(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="2em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1024 1024"
            >
              <path
                fill="#ffffff"
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4l-66.1.3c-4.4 0-8-3.5-8-8c0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4l66-.3c4.4 0 8 3.5 8 8c0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2c0 4.4-3.6 8-8 8z"
              />
            </svg>
          </div>
          <img className="add-image" src={Addvertisement} alt="" />
        </div>
      </div>
    </>
  );
};

export default Home;
